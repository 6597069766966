<template>
  <div class="news-detail">
    <el-form
      ref="postForm"
      :rules="rules"
      :model="postForm"
      label-width="80px"
      size="medium"
    >
      <el-form-item label="归属官网" v-if="site === 0">
        <el-radio-group
          v-model="postForm.site"
          :disabled="isEdit ? true : false"
          @change="changeSite"
        >
          <el-radio-button label="钱包官网"></el-radio-button>
          <el-radio-button label="博泉哈希"></el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标题" class="title" prop="title" required>
        <el-input v-model="postForm.title"></el-input>
      </el-form-item>
      <el-form-item label="英文标题" class="title" prop="en_title" required>
        <el-input v-model="postForm.en_title"></el-input>
      </el-form-item>
      <el-form-item label="内容" class="desc" prop="cn_desc" required>
        <el-input v-model="postForm.cn_desc"></el-input>
      </el-form-item>
      <el-form-item label="英文内容" class="desc" prop="en_desc" required>
        <el-input v-model="postForm.en_desc"></el-input>
      </el-form-item>
      <el-form-item label="封面">
        <el-upload
          action="https://www.ddpurse.com/uploadImage"
          list-type="picture-card"
          :limit="1"
          :file-list="appIconList"
          :on-success="uploadSuccess"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="主题" class="type" prop="type" required>
        <el-select v-model="postForm.type" placeholder="请选择主题">
          <el-option
            v-for="category in categoryList.items"
            :key="category.id"
            :label="category.article_type_name"
            :value="category.id"
          ></el-option>
          <!-- <el-option label="区块链课堂" :value="2"></el-option>
          <el-option label="热点资讯" :value="3"></el-option>-->
        </el-select>
      </el-form-item>
      <el-form-item label="中文" prop="content" required>
        <tinymce
          v-model="postForm.content"
          id="tinymce-content"
          :height="300"
        />
      </el-form-item>
      <el-form-item label="英文" prop="en_content" required>
        <tinymce
          v-model="postForm.en_content"
          id="tinymce-content-en"
          :height="300"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="onCancel">取消</el-button>
        <el-button type="primary" :loading="loading" @click="onSubmit">
          提交
        </el-button>
        <el-button type="primary" @click="onSave">
          保存草稿
        </el-button>
        <el-button type="primary" @click="onUse">
          使用草稿
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Tinymce from "@/components/Tinymce/index.vue";
import { fetchNewsDetail, postNews, editNews } from "@/api/news";

const SITE_MAP = {
  钱包官网: 1,
  博泉哈希: 2,
};
const SITE_TYPE = {
  1: "钱包官网",
  2: "博泉哈希",
};

export default {
  components: {
    Tinymce,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      postForm: {
        title: "",
        en_title: "",
        cn_desc: "",
        en_desc: "",
        type: "",
        content: "",
        en_content: "",
        site: "钱包官网",
      },
      loading: false,
      rules: {
        en_title: [
          { required: true, message: "请输入英文标题", trigger: "change" },
        ],
        en_desc: [
          { required: true, message: "请输入英文内容", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        cn_desc: [{ required: true, message: "请输入内容", trigger: "change" }],
        type: [{ required: true, message: "请选择主题", trigger: "change" }],
        content: [{ required: true, message: "请输入", trigger: "change" }],
        en_content: [{ required: true, message: "请输入", trigger: "change" }],
      },
      site: 0,
    };
  },
  computed: {
    ...mapGetters(["categoryList"]),
    appIconList() {
      if (this.postForm.pic_url) {
        return [
          {
            name: "pic_url",
            url: this.postForm.pic_url,
          },
        ];
      }
      return [];
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const site = urlParams.get("site");
    if (site) {
      this.site = +site;
    }

    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(id);
    }

    this.$store.dispatch("news/getCategoryList", {
      page: 1,
      size: 20,
      site: this.site || SITE_MAP[this.postForm.site],
    });
  },
  methods: {
    fetchData(id) {
      fetchNewsDetail(id).then((data) => {
        let json = data.data.data;
        json.site = SITE_TYPE[data.site];
        this.postForm = data.data.data;
      });
    },
    changeSite(val) {
      this.$store.dispatch("news/getCategoryList", {
        page: 1,
        size: 20,
        site: SITE_MAP[this.postForm.site],
      });
    },
    handleSuccess() {
      this.$message({
        title: "成功",
        message: this.isEdit ? "编辑文章成功" : "发布文章成功",
        type: "success",
        duration: 2000,
      });
      this.$router.go(-1);
    },
    submit() {
      const loadingInstance = this.$loading();
      let query = this.postForm;
      query.site = SITE_MAP[query.site];

      // 覆盖 site
      if (this.site) {
        query.site = this.site;
      }

      if (this.isEdit) {
        const { id, ...res } = query;
        editNews({ ...res, article_id: id })
          .then(() => {
            loadingInstance.close();
            this.handleSuccess();
          })
          .catch(() => {
            loadingInstance.close();
          });
      } else {
        postNews(query)
          .then(() => {
            loadingInstance.close();
            this.handleSuccess();
          })
          .catch(() => {
            loadingInstance.close();
          });
      }
    },
    uploadSuccess(data) {
      if (+data.code) {
        this.$message({
          message: "上传失败",
          duration: 3000,
          type: "waring",
        });
      } else {
        this.postForm.pic_url = data.data;
      }
    },
    onSubmit() {
      this.$refs["postForm"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$router.go(-1);
    },
    onSave() {
      try {
        const draftStr = JSON.stringify({ ...this.postForm });
        localStorage.setItem("NEWS_DRAFT", draftStr);
        this.$message.success("保存草稿成功");
      } catch (error) {
        this.$message.warning("保存草稿失败");
      }
    },
    onUse() {
      try {
        const draftStr = localStorage.getItem("NEWS_DRAFT");
        if (draftStr) {
          const draft = JSON.parse(draftStr);
          this.postForm = { ...draft };

          this.$nextTick(() => {
            window.tinymce
              .get("tinymce-content")
              .setContent(draft.content || "");
            window.tinymce
              .get("tinymce-content-en")
              .setContent(draft.en_content || "");
          });

          this.$message.success("使用草稿成功");
        } else {
          this.$message.warning("暂无草稿");
        }
      } catch (error) {
        this.$message.warning("使用草稿失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.news-detail {
  margin: 0;
  padding: 40px 20px;

  .title {
    width: 500px;
  }

  .desc {
    width: 1000px;
  }

  .pic {
    width: 500px;
  }
}
</style>
