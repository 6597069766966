// Here is a list of the toolbar
// Detail list see https://www.tinymce.com/docs/advanced/editor-control-identifiers/#toolbarcontrols

// 自定义样式 TODO
// https://www.tiny.cloud/docs/demo/format-custom/

const toolbar = [
  "searchreplace bold italic underline strikethrough formatselect fontselect fontsizeselect styleselect alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample",
  "hr bullist numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen"
];

export default toolbar;
