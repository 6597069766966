<template>
  <div
    :class="{ fullscreen: fullscreen }"
    class="tinymce-container"
    :style="{ width: containerWidth }"
  >
    <textarea :id="tinymceId" class="tinymce-textarea" />
    <!-- <div class="editor-custom-btn-container">
      <editorImage color="#1890ff" class="editor-upload-btn" @successCBK="imageSuccessCBK" />
    </div>-->
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
// import editorImage from './components/EditorImage'
import plugins from "./plugins";
import toolbar from "./toolbar";
import load from "./dynamicLoadScript";

// why use this cdn, detail see https://github.com/PanJiaChen/tinymce-all-in-one
const tinymceCDN = "https://static.ddpurse.com/timymce/tinymce.min.js";

export default {
  name: "Tinymce",
  // components: { editorImage },
  props: {
    id: {
      type: String,
      default: function() {
        return "vue-tinymce-" + +new Date() + ((Math.random() * 1000).toFixed(0) + "");
      },
    },
    value: {
      type: String,
      default: "",
    },
    toolbar: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    menubar: {
      type: String,
      default: "file edit insert view format table",
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360,
    },
    width: {
      type: [Number, String],
      required: false,
      default: "auto",
    },
  },
  data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        en: "en",
        zh: "zh_CN",
        es: "es_MX",
        ja: "ja",
      },
    };
  },
  computed: {
    containerWidth() {
      const width = this.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`;
      }
      return width;
    },
  },
  watch: {
    value(val) {
      if (!this.hasChange && this.hasInit) {
        this.$nextTick(() => window.tinymce.get(this.tinymceId).setContent(val || ""));
      }
    },
  },
  mounted() {
    this.init();
  },
  activated() {
    if (window.tinymce) {
      this.initTinymce();
    }
  },
  deactivated() {
    this.destroyTinymce();
  },
  destroyed() {
    this.destroyTinymce();
  },
  methods: {
    init() {
      // dynamic load tinymce from cdn
      load(tinymceCDN, (err) => {
        if (err) {
          this.$message.error(err.message);
          return;
        }
        this.initTinymce();
      });
    },
    initTinymce() {
      const _this = this;
      window.tinymce.init({
        selector: `#${this.tinymceId}`,
        language: this.languageTypeList["zh"],
        height: this.height,
        body_class: "panel-body ",
        object_resizing: false,
        toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar,
        fontsize_formats: "11px 12px 14px 16px 18px 24px 36px 48px",
        style_formats: [
          {
            title: "行高",
            items: [
              { title: "1", block: "p", styles: { "line-height": "1.0" } },
              { title: "1.1", block: "p", styles: { "line-height": "1.1" } },
              { title: "1.2", block: "p", styles: { "line-height": "1.2" } },
              { title: "1.3", block: "p", styles: { "line-height": "1.3" } },
              { title: "1.4", block: "p", styles: { "line-height": "1.4" } },
              { title: "1.5", block: "p", styles: { "line-height": "1.5" } },
              { title: "1.6", block: "p", styles: { "line-height": "1.6" } },
              { title: "1.7", block: "p", styles: { "line-height": "1.7" } },
              { title: "1.8", block: "p", styles: { "line-height": "1.8" } },
              { title: "2", block: "p", styles: { "line-height": "2.0" } },
            ],
          },
          {
            title: "段落距离",
            items: [
              { title: "1", block: "p", styles: { margin: "1em 0" } },
              { title: "1.1", block: "p", styles: { margin: "1.1em 0" } },
              { title: "1.2", block: "p", styles: { margin: "1.2em 0" } },
              { title: "1.3", block: "p", styles: { margin: "1.3em 0" } },
              { title: "1.4", block: "p", styles: { margin: "1.4em 0" } },
              { title: "1.5", block: "p", styles: { margin: "1.5em 0" } },
              { title: "1.6", block: "p", styles: { margin: "1.6em 0" } },
              { title: "1.7", block: "p", styles: { margin: "1.7em 0" } },
              { title: "1.8", block: "p", styles: { margin: "1.8em 0" } },
              { title: "1.9", block: "p", styles: { margin: "1.9em 0" } },
              { title: "2", block: "p", styles: { margin: "2em 0" } },
            ],
          },
        ],
        style_formats_merge: true,
        style_formats_autohide: true,
        menubar: this.menubar,
        plugins: plugins,
        images_upload_url: "https://www.ddpurse.com/uploadImage",
        end_container_on_empty_block: true,
        powerpaste_word_import: "clean",
        code_dialog_height: 450,
        code_dialog_width: 1000,
        advlist_bullet_styles: "square",
        advlist_number_styles: "default",
        imagetools_cors_hosts: ["www.tinymce.com", "codepen.io"],
        default_link_target: "_blank",
        link_title: false,
        nonbreaking_force_tab: true, // inserting nonbreaking space &nbsp; need Nonbreaking Space Plugin
        content_style: "p {margin: 0px; border:0px ; padding: 1px}",
        init_instance_callback: (editor) => {
          if (_this.value) {
            editor.setContent(_this.value);
          }
          _this.hasInit = true;
          editor.on("NodeChange Change KeyUp SetContent", () => {
            this.hasChange = true;
            this.$emit("input", editor.getContent());
          });
        },
        setup(editor) {
          editor.on("FullscreenStateChanged", (e) => {
            _this.fullscreen = e.state;
          });
        },
        // 整合七牛上传
        // images_dataimg_filter(img) {
        //   setTimeout(() => {
        //     const $image = $(img);
        //     $image.removeAttr('width');
        //     $image.removeAttr('height');
        //     if ($image[0].height && $image[0].width) {
        //       $image.attr('data-wscntype', 'image');
        //       $image.attr('data-wscnh', $image[0].height);
        //       $image.attr('data-wscnw', $image[0].width);
        //       $image.addClass('wscnph');
        //     }
        //   }, 0);
        //   return img
        // },
        images_upload_handler(blobInfo, success, failure, progress) {
          const url = "https://www.ddpurse.com/uploadImage";
          const formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());

          let xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open("POST", url);

          xhr.onload = function() {
            let json = JSON.parse(xhr.responseText);
            if (!json || typeof json.data !== "string") {
              failure("Invalid JSON: " + xhr.responseText);
              return;
            }
            success(json.data);
          };

          xhr.send(formData);
        },
      });
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId);
      if (this.fullscreen) {
        tinymce.execCommand("mceFullScreen");
      }

      if (tinymce) {
        tinymce.destroy();
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value);
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent();
    },
    imageSuccessCBK(arr) {
      const _this = this;
      arr.forEach((v) => {
        window.tinymce.get(_this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`);
      });
    },
  },
};
</script>

<style scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}
.tinymce-container >>> .mce-fullscreen {
  z-index: 10000;
}
.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}
.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}
.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}
.editor-upload-btn {
  display: inline-block;
}
</style>
